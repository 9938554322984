import React, { useEffect, useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Typography, useMediaQuery, Button } from '@mui/material';
import CurrencyDollar from '../../icons/CurrencyDollar';
import AuthContext from '../../contexts/JWTContext';
import UsersIcon from '../../icons/Users';
import QueueIcon from '@mui/icons-material/Queue';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import CustomLogout from './CustomLogout';
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';
import { capitalize } from 'lodash';
import HomeIcon from '@mui/icons-material/Home';

const DashboardSidebar = (props) => {
  const [sections, setSections] = React.useState([]);
  const { settings, saveSettings } = useSettings();

  const handleThemeChange = () => {
    console.log('Current theme:', settings.theme);
    saveSettings({
      ...settings,
      theme: settings.theme === 'LIGHT' ? 'DARK' : 'LIGHT'
    });
  };

  useEffect(() => {
    setSections([
      {
        title: 'Navigation',
        items: [
          {
            title: 'Home',
            path: '/dashboard',
            icon: <HomeIcon fontSize="small" />,
          }
        ]
      },
      {
        title: 'Main Lists',
        items: [
          {
            title: 'Purchases',
            path: '/dashboard/purchases',
            icon: <UsersIcon fontSize="small" />,
            roles: ['admin', 'ops', 'doctor', 'sales'],
          },
          {
            title: 'Customers',
            path: '/dashboard/customers',
            icon: <UsersIcon fontSize="small" />,
            roles: ['admin', 'ops', 'doctor', 'sales'],
          },
          {
            title: 'Sales',
            path: '/dashboard/sales',
            icon: <UsersIcon fontSize="small" />,
            roles: ['admin', 'ops', 'sales', 'doctor'],
          },
          {
            title: 'Connect',
            path: '/dashboard/connect',
            icon: <UsersIcon fontSize="small" />,
            roles: ['admin', 'ops', 'doctor', 'sales'],
          },
          {
            title: 'Clinicians',
            path: '/dashboard/clinicians',
            icon: <UsersIcon fontSize="small" />,
            roles: ['admin', 'ops'],
          },
          {
            title: 'Support Group Surveys',
            path: '/dashboard/support-group-surveys',
            icon: <QueueIcon fontSize="small" />,
            roles: ['admin', 'ops'],
          },
        ]
      },
      {
        title: 'Clinical Queues',
        items: [
          {
            title: 'Naltrexone Refills',
            path: '/dashboard/customers/upcoming-nax-refills',
            icon: <QueueIcon fontSize="small" />,
            roles: ['admin', 'ops', 'doctor'],
          },
          {
            title: 'Async Consultations',
            path: '/dashboard/async-consultations',
            icon: <QueueIcon fontSize="small" />,
            roles: ['admin', 'ops', 'doctor'],
          },
          {
            title: 'Insurance Consults',
            path: '/dashboard/customers/upcoming-insurance-consults',
            icon: <QueueIcon fontSize="small" />,
            roles: ['admin', 'ops', 'doctor'],
          },
          {
            title: 'Doctor Requests',
            path: '/dashboard/doctor-requests',
            icon: <QueueIcon fontSize="small" />,
            roles: ['admin', 'ops', 'doctor'],
          },
        ]
      },
      {
        title: 'Payments',
        items: [
          {
            title: 'Plans',
            path: '/dashboard/nmi/plans',
            icon: <CurrencyDollar fontSize="small" />,
            roles: ['admin', 'ops', 'sales'],
          },
          {
            title: 'Orders',
            path: '/dashboard/orders',
            icon: <CurrencyDollar fontSize="small" />,
            roles: ['admin', 'ops', 'doctor', 'sales'],
          },
          {
            title: 'Collections',
            path: '/dashboard/collections',
            icon: <CurrencyDollar fontSize="small" />,
            roles: ['admin', 'ops'],
          },
        ]
      },
      {
        title: 'Settings',
        items: [
          {
            title: `Switch to ${settings?.theme === 'LIGHT' ? 'Dark' : 'Light'} Mode`,
            icon: settings?.theme === 'LIGHT' ? <DarkModeIcon fontSize="small" /> : <LightModeIcon fontSize="small" />,
            onClick: handleThemeChange
          }
        ]
      },
    ]);
  }, [settings?.theme]);

  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { isInitialized } = useContext(AuthContext);
  const { user } = useAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const userRoles = user?.roles;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box sx={{ height: 64 }} />
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ p: 1 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              px: 2,
              py: 1,
              mb: 2,
              mt: 2,
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Typography
              color="textPrimary"
              variant="subtitle1"
              fontWeight="medium"
              sx={{ marginX: 'auto', display: 'flex', alignItems: 'center' }}
            >
              {`${capitalize(user.firstname)} ${capitalize(user.lastname)}`}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Button
            onClick={handleThemeChange}
            sx={{ mb: 2 }}
            variant="contained"
          >
            Switch Theme (Test)
          </Button>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                },
                '& .MuiListItem-root': {
                  borderRadius: 1,
                  mb: 0.5,
                  '&:hover': {
                    backgroundColor: 'action.hover'
                  }
                },
                '& .MuiListItemIcon-root': {
                  minWidth: 40
                },
                '& .MuiTypography-subtitle2': {
                  fontSize: '0.875rem',
                  fontWeight: 500
                }
              }}
              {...section}
              items={section.items.filter((item) => (
                !item?.roles || item?.roles?.some((role) => userRoles?.includes(role))
              ))}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2, mt: 2 }}>
          <CustomLogout />
        </Box>
      </Scrollbar>
    </Box>
  );

  if (!isMobile) {
    return null;
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      variant="temporary"
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          boxShadow: (theme) => theme.shadows[8]
        }
      }}
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
